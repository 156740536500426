import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { menuItems, sidebarMenuItems } from './MenuItem'
import { CookiesHelper, goToHomePage, showSnackBar, showLoader } from '../../helpers/Utils'
import ConfirmationModal from '../common/ConfirmationModal'
import { useTranslation } from 'react-i18next'

function Navigation ({ authActions, authState, dashboardState }) {
  const [selectedMenu, setSelectedMenu] = useState('')
  const { t } = useTranslation(['dashboard'])
  const [logoutModalOpen, setLogoutModalOpen] = useState(false)
  const [openMenus, setOpenMenus] = useState({})
  const [selectedLanguage, setSelectedLanguage] = useState('German')

  useEffect(() => {
    const defaultLanguage = CookiesHelper.getItem('defaultLanguage')
    if (defaultLanguage) {
      setSelectedLanguage(defaultLanguage === 'en' ? 'English' : 'German')
    }
  }, [])

  const handleMenuClick = (menu) => {
    setSelectedMenu(menu)
  }

  const handleGoToHomePage = () => {
    window.location.href = '/'
  }

  const onLanguageChange = (lang, langName) => {
    CookiesHelper.setItem('defaultLanguage', lang, 604800, '/')
    setSelectedLanguage(langName)
    window.location.reload()
  }

  const handleLogout = () => {
    showLoader()
    showSnackBar(open, { displayMsg: authState?.authResponse })
    authActions.tryLogout()
    goToHomePage()
  }

  const isMenuActive = (subItems) => {
    return subItems?.some((subItem) => '/' + subItem?.url === window.location.pathname)
  }

  const toggleNestedMenu = (menu) => {
    setOpenMenus((prevState) => ({
      ...prevState,
      [menu]: !prevState[menu]
    }))
  }

  return (
    <>
      <nav className='navbar show navbar-vertical h-lg-screen navbar-expand-lg px-0 py-3 navbar-light bg-white border-bottom border-bottom-lg-0 border-end-lg' id='navbarVertical'>
        <div className='container-fluid'>
          <button className='navbar-toggler ms-n2' type='button' data-bs-toggle='collapse' data-bs-target='#sidebarCollapse' aria-controls='sidebarCollapse' aria-expanded='false' aria-label='Toggle navigation'>
            <span className='navbar-toggler-icon' />
          </button>
          <div className='navbar-brand py-lg-2 mb-lg-5 px-lg-6 me-0 vertical-center cursor-pointer'>
            <img src='https://d3brnpc5nhvc9v.cloudfront.net/static-assets-helloparts/logos/helloparts-Logo-x.png' alt='logo' className='logo' onClick={handleGoToHomePage} />
          </div>
          <div className='collapse navbar-collapse' id='sidebarCollapse'>
            <ul className='navbar-nav'>
              {sidebarMenuItems?.map((menu, index) => {
                const menuConfig = menuItems[menu]
                if (menuConfig?.subItems) {
                  const isActive = isMenuActive(menuConfig?.subItems)
                  return (
                    <li className='nav-item' key={menuConfig?.name + index}>
                      <div className={`nav-link cursor-pointer ${isActive ? 'active' : ''}`} onClick={() => toggleNestedMenu(menu)}>
                        {menuConfig.icon} {menu === 'language' ? selectedLanguage : ''}
                        <i className={`gp-ml8 bi bi-chevron-${openMenus[menu] ? 'up' : 'down'}`} />
                      </div>
                      {openMenus[menu] && (
                        <ul className='nested-menu' style={{ listStyleType: 'none', paddingLeft: '20px' }}>
                          {menuConfig?.subItems?.map((subItem, subIndex) => (
                            <li className='nav-item' key={subItem.name + subIndex}>
                              <Link
                                className={`nav-link submenu-text ${'/' + subItem.url === window.location.pathname ? 'highlight' : ''}`}
                                to={subItem?.url}
                                onClick={(e) => {
                                  e.preventDefault()
                                  const langCode = subItem.url.split('/').pop()
                                  onLanguageChange(langCode, subItem.name)
                                }}
                              >
                                {subItem?.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  )
                } else {
                  return (
                    <li className='nav-item' key={menuConfig?.name + index}>
                      <Link className={`nav-link ${'/' + menuConfig?.url === window.location.pathname ? 'active' : ''}`} onClick={() => handleMenuClick(menu)}>
                        {menuConfig?.icon}{menuConfig?.name}
                      </Link>
                    </li>
                  )
                }
              })}
            </ul>

            <hr className='navbar-divider my-5 opacity-20' />
            <div className='mt-auto' />
            <ul className='navbar-nav'>
              <li className='nav-item'>
                <Link className={`nav-link ${selectedMenu === 'accounts' || window.location.pathname === '/accounts' ? 'active' : ''}`}>
                  <i className='bi bi-person-square' /> {dashboardState?.dashboardData?.first_name || ''}
                </Link>
              </li>
              <li className='nav-item' onClick={() => setLogoutModalOpen(true)}>
                <Link className='nav-link' href='#'>
                  <i className='bi bi-box-arrow-left' />{t('logout')}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <ConfirmationModal
        show={logoutModalOpen}
        onHide={() => setLogoutModalOpen(false)}
        onConfirm={() => handleLogout()}
        message={t('logoutText')}
        title={t('logout')}
        confirmBtnTxt={t('yes')}
        cancelBtnTxt={t('no')}
      />
    </>
  )
}

export default Navigation
