import React from 'react'
import { Navigate } from 'react-router-dom'
import Login from '../components/auth/Login'
import SignUp from '../components/auth/Signup'
import Dashboard from '../components/dashboard/Dashboard'
import { USER_RESPONSE_KEY } from '../constants/siteConstant'
import ForgotPassword from '../components/auth/ForgotPassword'
import ResetPassword from '../components/auth/ResetPassword'
import LandingPage from '../components/pages/LandingPage'
import ViewRequest from '../components/pages/ViewRequest'
import Register from '../components/auth/Signup-v2'
import AccountDetails from '../components/dashboard/AcountDetails'

export const ROUTES_WITHOUT_SIDEBAR = (props) => {
  const isLoggedIn = window.localStorage.getItem(USER_RESPONSE_KEY)
  return [
    {
      path: '/login',
      key: 'LOGIN',
      exact: true,
      component: (
        <Login
          authActions={props.actions.authActions}
          authState={props.authState}
        />
      )
    },
    {
      path: '/signup',
      key: 'SIGUP',
      exact: true,
      component: (
        <SignUp
          authActions={props.actions.authActions}
          authState={props.authState}
        />
      )
    },
    {
      path: '/register',
      key: 'REGISTER',
      exact: true,
      component: (
        <Register />
      )
    },
    {
      path: '/forgot-password',
      key: 'FORGOT',
      exact: true,
      component: (
        <ForgotPassword
          authActions={props.actions.authActions}
          authState={props.authState}
        />
      )
    },
    {
      path: '/reset-password',
      key: 'RESET',
      exact: true,
      component: (
        <ResetPassword
          authActions={props.actions.authActions}
          authState={props.authState}
        />
      )
    },
    {
      path: '/dashboard',
      key: 'DASHBOARD',
      exact: true,
      component:
        isLoggedIn
          ? (
            <Dashboard
              authActions={props.actions.authActions}
              authState={props.authState}
              dashboardActions={props.actions.dashboardActions}
              dashboardState={props.dashboardState}
            />
            )
          : (
            <Navigate to='/login' />
            )
    },
    {
      path: '/account',
      key: 'ACCOUNT',
      exact: true,
      component:
        isLoggedIn
          ? (
            <AccountDetails
              authActions={props.actions.authActions}
              authState={props.authState}
              dashboardActions={props.actions.dashboardActions}
              dashboardState={props.dashboardState}
            />
            )
          : (
            <Navigate to='/login' />
            )
    },
    {
      path: '*',
      key: 'ROUTE_NOT_MATCHED',
      exact: true,
      component: <LandingPage />
    },
    {
      path: '/view-request',
      key: 'VIEW_REQUEST',
      exact: true,
      component: <ViewRequest />
    }
  ]
}
