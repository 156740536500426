import React, { useState } from 'react'
import { styled } from '@mui/system'
import { Button, Link, Input, Typography } from '@mui/material'
import '../pages/viewRequest.scss'
import { showLoader, hideLoader, goToHomePage } from '../../helpers/Utils'
import { useTranslation } from 'react-i18next'
import { CDN_URL } from '../../constants/siteConstant'
import { useNavigate } from 'react-router-dom'
import { tryForgettingPassword } from '../../services/auth'
import Copyright from '../common/Copyright'

const MUIInput = styled(Input)(({ error }) => ({
  '&&&': {
    display: 'flex',
    backgroundColor: 'white',
    border: `2px solid ${error ? 'red' : '#EBEAED'}`,
    padding: '12px 0px 12px 24px',
    borderRadius: '100px',
    fontFamily: 'DMSans',
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '26px',
    width: '330px'
  }
}))

const ForgotPassword = () => {
  const [steps, setSteps] = useState(1)
  const [emailId, setEmailId] = useState('')
  const [error, setError] = useState(false)
  const { t } = useTranslation(['auth'])
  const navigate = useNavigate()

  const handleChange = (e) => {
    setEmailId(e.target.value)
    if (e.target.value) {
      setError(false)
    }
  }

  const handleSubmit = async () => {
    if (!emailId) {
      setError(true)
      return
    }
    showLoader()
    const response = await tryForgettingPassword(emailId)
    if (response) {
      setSteps(steps + 1)
    }
    hideLoader()
  }

  const handleBackToRegistrationPage = () => {
    navigate('/register')
  }

  return (

    <div className='view-request-container'>

      <section className='step-1-wrapper'>
        <div className='left-container'>
          <div
            className='h-full bg-cover vertical-center flex-d-column bg-image' style={{
              backgroundImage: `url(${CDN_URL + '/landing-page/helloparts_hero_full.png'
                })`
            }}
          >
            <div className='left-wrapper'>
              <div
                className='d-flex items-center flex-d-column title-cont welcome-text-wrapper'
              >
                <span className='font-Manrope gp-text28 gp-pt40 font-weight-700 d-flex text-white leading-52 -tracking-04'>
                  {t('welcomeToHelloParts')}
                </span>
                <span className='font-Manrope gp-text20 font-weight-400 leading-26 text-white gp-pt4'>
                  {t('partsMadeEasy')}
                </span>
              </div>
              <div
                className='d-flex items-center flex-d-column title-cont lead-container'
              >
                <span className='font-Inconsolata font-weight-700 d-flex text-white leading-52 -tracking-04'>
                  {t('pageTitle')}
                </span>
                <span className='font-Manrope gp-text16 font-weight-400 leading-26 text-white gp-pt16 page-subtitle'>
                  {t('pageSubtitle')}
                </span>
              </div>
            </div>
          </div>
        </div>
        {steps === 1 &&
          <div className='right-container'>
            <div
              className='d-flex  flex-d-column rounded-10 form-cont'
            >
              <div className='logo-container gp-mt120'>
                <img src='https://d3brnpc5nhvc9v.cloudfront.net/static-assets-helloparts/logos/helloparts-Logo-x.png' alt='helloparts' height='40px' className='cp logo-style' onClick={goToHomePage} />
              </div>
              <div
                className='d-flex flex-d-column input-cont'
              >
                <span className='font-DMSans gp-text24 font-weight-500 leading-26 gp-mt20' style={{ color: '#9C9AA5' }}>{t('forgotPassword')}</span>
                <section className='input-wrapper h_86'>
                  <div className='d-flex flex-d-column'>
                    <label className='gp-mb8 common-label required font-Manrope'>{t('enterEmailAddress')}</label>
                    <MUIInput
                      name='email'
                      onChange={handleChange}
                      id='email'
                      disableUnderline
                      value={emailId}
                      error={error}
                      style={{
                        fontSize: '12px'
                      }}
                      placeholder={t('email')}
                    />
                  </div>
                  <Button
                    type='submit'
                    fullWidth
                    variant='contained'
                    style={{
                      borderRadius: '8px',
                      textTransform: 'none',
                      color: 'white',
                      backgroundColor: '#6926D9',
                      padding: '10px 0px 10px 0px',
                      fontWeight: '500',
                      fontSize: '16px'
                    }}
                    className='font-Manrope gp-mt32'
                    onClick={handleSubmit}
                  >
                    {t('resetPwd')}
                  </Button>
                  <div className='font-Manrope gp-mt24 tc'>
                    <Link href='/registration' underline='hover' className='font-Manrope' sx={{ textAlign: 'center', color: '#919191 !important', fontWeight: '400 !important' }}>
                      {t('backToRegistration')}
                    </Link>

                  </div>
                </section>
                <Copyright />
              </div>
            </div>
          </div>}
        {steps === 2 &&
          <div className='right-container'>
            <div
              className='d-flex  flex-d-column rounded-10 form-cont'
            >
              <div className='logo-container gp-mt120'>
                <img src='https://d3brnpc5nhvc9v.cloudfront.net/static-assets-helloparts/logos/helloparts-Logo-x.png' alt='helloparts' height='40px' className='cp logo-style' onClick={goToHomePage} />
              </div>
              <div
                className='d-flex flex-d-column input-cont'
              >
                <section className='input-wrapper h_96'>
                  <Typography className='font-Manrope' style={{ fontWeight: '400 !important', width: '300px', color: '#919191' }}>
                    Wir haben Ihnen eine E-Mail mit
                    Informationen geschickt, wie Sie Ihr
                    Passwort zurücksetzen können.Bitte sehen Sie auch im
                    Spam-Ordner nach.
                  </Typography>
                  <Button
                    type='submit'
                    fullWidth
                    variant='contained'
                    style={{
                      borderRadius: '8px',
                      textTransform: 'none',
                      color: 'white',
                      backgroundColor: '#6926D9',
                      padding: '10px 0px 10px 0px',
                      fontWeight: '500',
                      fontSize: '16px'
                    }}
                    className='font-Manrope gp-mt32'
                    onClick={handleBackToRegistrationPage}
                  >
                    {t('backToRegistration')}
                  </Button>
                </section>
                <Copyright />
              </div>
            </div>
          </div>}
      </section>
    </div>
  )
}

export default ForgotPassword
