import React from 'react'
import { Button, Input, MenuItem, TextField } from '@mui/material'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { goToHomePage } from '../../helpers/Utils'

const MUIInput = styled(Input)({
  '&&&': {
    display: 'flex',
    backgroundColor: 'white',
    border: '2px solid #EBEAED',
    padding: '12px 0px 12px 24px',
    borderRadius: '100px',
    fontFamily: 'DMSans',
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '26px',
    width: '330px'
  }
})

const ErrorSpan = styled('span')({
  marginTop: '4px',
  color: 'red',
  fontFamily: 'DMSans',
  fontSize: '12px'
})

export default function ViewRequestActiveStep2 ({ formData, handleChange, dmsList, onStep2CTA, activeStep, setActiveStep }) {
  const { t } = useTranslation(['viewRequest'])

  return (
    <section className='step-2-wrapper'>
      <div className='step-2-contents'>
        <div className='content-wrapper'>
          <div className='logo-container gp-mt40 tc'>
            <img src='https://d3brnpc5nhvc9v.cloudfront.net/static-assets-helloparts/logos/helloparts-Logo-x.png' alt='helloparts' height='40px' className='cp logo-style' onClick={goToHomePage} />
          </div>
          <div className='gp-mt16 gp-mb12 tc light-grey-color gp-text14'>1/2</div>
          <div className='gp-mt16 black-text gp-text22 tc'>Ihre Stammdaten</div>
          <div className='gp-mt16 light-grey-color gp-text16 tc'>Korrigieren oder ergänzen Sie fehlende Informationen</div>
          <div className='input-wrapper'>
            <div className='d-flex flex-d-column gp-mt20'>
              <label className='common-label gp-mb8 required'>Name der Werkstatt</label>
              <MUIInput
                value={formData?.workshopAccountName || ''}
                disableUnderline
                disabled
                style={{
                  width: '100%',
                  fontSize: '12px'
                }}
                placeholder='Autoservice Max Mustermann'
              />
            </div>
            <div className='d-flex flex-d-column gp-mt20'>
              <label className='common-label gp-mb8 required'>DMS (Werkstattsoftware) in Verwendung</label>
              <TextField
                name='dmsId'
                value={formData.dmsId || ''}
                onChange={handleChange}
                select
                variant='standard'
                placeholder={t('currentDMSPlaceholder')}
                InputLabelProps={{
                  style: {
                    paddingLeft: '10px',
                    fontFamily: 'DMSans',
                    fontSize: '12px',
                    fontWeight: 500
                  }
                }}
                className='dms-select'
                InputProps={{
                  style: {
                    border: formData.dmsIdError ? '2px solid red' : '2px solid #EBEAED',
                    borderRadius: '100px',
                    height: '45px',
                    fontSize: '12px'
                  },
                  disableUnderline: true
                }}
              >
                {dmsList.map((el) => {
                  return (
                    <MenuItem
                      key={el.id} value={el.id}
                      sx={{
                        '&.Mui-selected': {
                          backgroundColor: '#EBEAED !important'
                        },
                        '&.Mui-selected:hover': {
                          backgroundColor: 'transparent'
                        }
                      }}
                    >
                      {el.name}
                    </MenuItem>
                  )
                })}
              </TextField>
              <ErrorSpan>{formData.dmsIdError}</ErrorSpan>
            </div>
            {/* Input for others dms */}
            {formData.dmsId === 9999 &&
              <div className='d-flex flex-d-column gp-mt20'>
                <label className='common-label gp-mb8 required'>Name des DMS</label>
                <MUIInput
                  name='dmsName'
                  value={formData.dmsName || ''}
                  onChange={handleChange}
                  disableUnderline
                  style={{
                    borderColor: formData.dmsNameError ? 'red' : '#EBEAED',
                    width: '100%',
                    fontSize: '12px'
                  }}
                  placeholder={t('DMSNamePlaceholder')}
                />
                <ErrorSpan>{formData.dmsNameError}</ErrorSpan>
              </div>}

            <div className='d-flex flex-d-column gp-mt20'>
              <label className='common-label gp-mb8 required'>Ihre Kundennummer / DMS Customer ID / Referenznummer</label>
              <MUIInput
                name='dmsAccountId'
                value={formData.dmsAccountId || ''}
                onChange={handleChange}
                disableUnderline
                style={{
                  borderColor: formData.dmsAccountIdError ? 'red' : '#EBEAED',
                  width: '100%',
                  fontSize: '12px'
                }}
                placeholder={t('DMSCustomerIdPlaceholder')}
              />
              <ErrorSpan>{formData.dmsAccountIdError}</ErrorSpan>
            </div>
            <div className='gp-mt20 tc'>
              <Button
                onClick={onStep2CTA}
                style={{
                  borderRadius: '8px',
                  textTransform: 'none',
                  color: 'white',
                  backgroundColor: '#6926D9',
                  padding: '10px 30px 10px 30px',
                  fontWeight: '500',
                  fontSize: '14px'
                }}
              >
                <span className='font-DMSans gp-text18 font-weight-500 leading-26 text-white'>Weiter</span>
              </Button>
            </div>
            <p className='tc gp-text18 light-grey-color cursor-hand' onClick={() => setActiveStep(activeStep + 1)}>Überspringen und später einrichten</p>
          </div>
        </div>
      </div>
    </section>
  )
}
