import React from 'react'
import { Button, Input } from '@mui/material'
import { CDN_URL } from '../../constants/siteConstant'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { goToHomePage } from '../../helpers/Utils'

const MUIInput = styled(Input)({
  '&&&': {
    display: 'flex',
    backgroundColor: 'white',
    border: '2px solid #EBEAED',
    padding: '12px 0px 12px 24px',
    borderRadius: '100px',
    fontFamily: 'DMSans',
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '26px',
    width: '330px'
  }
})

const ErrorSpan = styled('span')({
  marginTop: '4px',
  color: 'red',
  fontFamily: 'DMSans',
  fontSize: '12px'
})

export default function ViewRequestActiveStep1 ({ formData, handleChange, handleSubmit }) {
  const { t } = useTranslation(['viewRequest'])

  return (
    <section className='step-1-wrapper'>
      <div className='left-container'>
        <div
          className='h-full bg-cover vertical-center flex-d-column bg-image' style={{
            backgroundImage: `url(${CDN_URL + '/landing-page/helloparts_hero_full.png'
              })`
          }}
        >
          <div className='left-wrapper'>
            <div
              className='d-flex items-center flex-d-column title-cont welcome-text-wrapper'
            >
              <span className='font-Manrope gp-text28 gp-pt40 font-weight-700 d-flex text-white leading-52 -tracking-04'>
                {t('welcomeToHelloParts')}
              </span>
              <span className='font-Manrope gp-text20 font-weight-400 leading-26 text-white gp-pt4'>
                {t('partsMadeEasy')}
              </span>
            </div>
            <div
              className='d-flex items-center flex-d-column title-cont lead-container'
            >
              <span className='font-Inconsolata font-weight-700 d-flex text-white leading-52 text-42 -tracking-04'>
                {t('pageTitle')}
              </span>
              <span className='font-Manrope gp-text16 font-weight-400 leading-26 text-white gp-pt16 page-subtitle'>
                {t('pageSubtitle')}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className='right-container'>
        <div
          className='d-flex  flex-d-column rounded-10 form-cont'
        >
          <div className='logo-container gp-mt12'>
            <img src='https://d3brnpc5nhvc9v.cloudfront.net/static-assets-helloparts/logos/helloparts-Logo-x.png' alt='helloparts' height='40px' className='cp logo-style' onClick={goToHomePage} />
          </div>
          <div
            className='d-flex flex-d-column input-cont'
          >
            <span className='font-Manrope gp-text24 font-weight-500 leading-26 gp-mt20' style={{ color: '#9C9AA5' }}>Registrieren</span>
            <section className='input-wrapper'>
              <div className='d-flex flex-d-column'>
                <label className='gp-mb8 common-label required'>{t('emailAddress')}</label>
                <MUIInput
                  name='email'
                  value={formData.email}
                  onChange={handleChange}
                  disabled
                  disableUnderline
                  style={{
                    borderColor: formData.emailError ? 'red' : '#EBEAED',
                    fontSize: '12px'
                  }}
                  placeholder='max@mustermann.de'
                />
                <ErrorSpan>{formData.emailError}</ErrorSpan>
              </div>

              <div className='d-flex flex-d-column gp-mt20'>
                <label className='gp-mb8 common-label required'>{t('firstName')}</label>
                <MUIInput
                  name='firstName'
                  value={formData.firstName}
                  onChange={handleChange}
                  disableUnderline
                  style={{
                    borderColor: formData.firstNameError ? 'red' : '#EBEAED',
                    fontSize: '12px'
                  }}
                  placeholder='Max'
                />
                <ErrorSpan>{formData.firstNameError}</ErrorSpan>
              </div>

              <div className='d-flex flex-d-column gp-mt20'>
                <label className='gp-mb8 common-label'>{t('lastName')}</label>
                <MUIInput
                  name='lastName'
                  value={formData.lastName}
                  onChange={handleChange}
                  disableUnderline
                  placeholder='Mustermann'
                  style={{ fontSize: '12px' }}
                />
              </div>

              <div className='d-flex flex-d-column gp-mt20'>
                <label className='gp-mb8 common-label required'>{t('password')}</label>
                <MUIInput
                  type='password'
                  name='password'
                  value={formData.password}
                  onChange={handleChange}
                  disableUnderline
                  style={{
                    borderColor: formData.passwordError ? 'red' : '#EBEAED',
                    fontSize: '12px'
                  }}
                  placeholder='Neues Passwort'
                />
                <ErrorSpan>{formData.passwordError}</ErrorSpan>
              </div>
            </section>
            <div className='gp-text14 tnc-para mrt_10'>{t('agreeTermsCondition')}
              <a className='black-text gp-ml4 tl-mr4' href='https://goparts.tech/privacy-policy' target='_blank' rel='noreferrer'>{t('privacyPolicy')}</a> {t('andThe')}
              <a className='black-text gp-ml4 tl-mr4' href='https://goparts.tech/terms-and-conditions' target='_blank' rel='noreferrer'>AGB</a> zu.
            </div>
            <Button
              onClick={handleSubmit}
              style={{
                borderRadius: '8px',
                textTransform: 'none',
                color: 'white',
                backgroundColor: '#6926D9',
                padding: '10px 0px 10px 0px',
                fontWeight: '500',
                fontSize: '16px'
              }}
            >
              <span className='font-DMSans gp-text18 font-weight-500 leading-26 text-white'>{t('createAccount')}</span>
            </Button>
          </div>
        </div>
      </div>
    </section>
  )
}
