import React from 'react'
import { Modal, Button } from 'react-bootstrap'

const ConfirmationModal = ({ show, onHide, onConfirm, message, title, confirmBtnTxt, cancelBtnTxt }) => {
  return (
    <Modal show={show} onHide={onHide} centered size='lg' animation scrollable>
      <Modal.Header closeButton className='clr-grey'>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className='clr-grey'>
        {message}
      </Modal.Body>
      <Modal.Footer className='clr-grey'>
        {cancelBtnTxt &&
        (
          <Button variant='secondary' onClick={onHide}>
            {cancelBtnTxt}
          </Button>
        )}
        {confirmBtnTxt &&
        (
          <Button variant='primary' onClick={onConfirm}>
            {confirmBtnTxt}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmationModal
