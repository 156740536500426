import React, { useState, useEffect } from 'react'
import { styled } from '@mui/system'
import { Button, Input, IconButton, InputAdornment, Typography } from '@mui/material'
import '../pages/viewRequest.scss'
import { useNavigate } from 'react-router-dom'
import { showLoader, hideLoader, getParameterByName, goToHomePage } from '../../helpers/Utils'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import LeftStaticScreen from '../common/LeftStaticScreen'
import Copyright from '../common/Copyright'
import { tryResettingPassword, tryResettingPasswordForNewAndExpiredUser } from '../../services/auth'

const MUIInput = styled(Input)(({ error }) => ({
  '&&&': {
    display: 'flex',
    backgroundColor: 'white',
    border: `2px solid ${error ? 'red' : '#EBEAED'}`,
    padding: '12px 0px 12px 24px',
    borderRadius: '100px',
    fontFamily: 'DMSans',
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '26px',
    width: '330px'
  }
}))

const ResetPassword = () => {
  const [state, setState] = useState({
    formData: { password: '', confirmPassword: '' },
    passwordError: '',
    confirmPasswordError: '',
    token: '',
    showPassword: { password: false, confirmPassword: false },
    steps: 1
  })

  const { t } = useTranslation(['auth'])
  const navigate = useNavigate()

  useEffect(() => {
    const res = getParameterByName('token', window.location.href)
    setState((prevState) => ({ ...prevState, token: res }))
  }, [])

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        [name]: value
      },
      passwordError: name === 'password' && value.length < 8 ? 'Passwort sollte mindestens 8 Zeichen lang sein' : '',
      confirmPasswordError: name === 'confirmPassword' ? '' : prevState.confirmPasswordError
    }))
  }

  const handleTogglePasswordVisibility = (field) => {
    setState((prevState) => ({
      ...prevState,
      showPassword: {
        ...prevState.showPassword,
        [field]: !prevState.showPassword[field]
      }
    }))
  }

  const handleSubmit = async () => {
    const { formData, token } = state
    let hasError = false
    if (!formData.password) {
      setState((prevState) => ({ ...prevState, passwordError: 'Passwort erforderlich' }))
      hasError = true
    }

    if (!formData.confirmPassword) {
      setState((prevState) => ({ ...prevState, confirmPasswordError: 'Bitte Passwort bestätigen' }))
      hasError = true
    }

    if (hasError) return

    if (formData.password === formData.confirmPassword) {
      try {
        showLoader()
        let response
        if (token) {
          response = await tryResettingPassword(token, formData.password, formData.confirmPassword)
        } else {
          response = await tryResettingPasswordForNewAndExpiredUser(formData.password, formData.confirmPassword)
        }
        if (response) {
          setState((prevState) => ({ ...prevState, steps: prevState.steps + 1 }))
        }
        hideLoader()
      } catch (error) {
        console.error('Error resetting password', error)
      }
    } else {
      setState((prevState) => ({ ...prevState, confirmPasswordError: 'Passwörter stimmen nicht überein' }))
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleSubmit()
    }
  }

  const navigateToLogin = () => {
      navigate('/login')
  }

  const { formData, passwordError, confirmPasswordError, showPassword, steps } = state

  return (
    <div className='view-request-container'>
      <section className='step-1-wrapper'>
        <LeftStaticScreen />
        {steps === 1 && (
          <div className='right-container'>
            <div className='d-flex flex-d-column rounded-10 form-cont'>
              <div className='logo-container gp-mt120'>
                <img src='https://d3brnpc5nhvc9v.cloudfront.net/static-assets-helloparts/logos/helloparts-Logo-x.png' alt='helloparts' height='40px' className='cp logo-style' onClick={goToHomePage} />
              </div>
              <div className='d-flex flex-d-column input-cont gp-mt24'>
                <span className='font-DMSans gp-text24 font-weight-500 leading-26' style={{ color: '#9C9AA5' }}>{t('resetPwd')}</span>
                <section className='input-wrapper h_87'>
                  <div className='d-flex flex-d-column gp-mb40'>
                    <label className='gp-mb8 common-label required font-Manrope'>{t('enterPwd')}</label>
                    <MUIInput
                      name='password'
                      type={showPassword.password ? 'text' : 'password'}
                      id='password'
                      onChange={handleChange}
                      disableUnderline
                      value={formData.password}
                      error={!!passwordError}
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton onClick={() => handleTogglePasswordVisibility('password')} edge='end'>
                            {showPassword.password ? <Visibility sx={{ fontSize: '14px' }} /> : <VisibilityOff sx={{ fontSize: '14px' }} />}
                          </IconButton>
                        </InputAdornment>
                      }
                      onKeyPress={handleKeyPress}
                      style={{ fontSize: '12px' }}
                      placeholder={t('password')}
                    />
                    {passwordError && <small className='error-text'>{passwordError}</small>}

                    <label className='gp-mb8 common-label required font-Manrope gp-mt24'>{t('reEnterPwd')}</label>
                    <MUIInput
                      name='confirmPassword'
                      type={showPassword.confirmPassword ? 'text' : 'password'}
                      id='confirmPassword'
                      onChange={handleChange}
                      disableUnderline
                      value={formData.confirmPassword}
                      error={!!confirmPasswordError}
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton onClick={() => handleTogglePasswordVisibility('confirmPassword')} edge='end'>
                            {showPassword.confirmPassword ? <Visibility sx={{ fontSize: '14px' }} /> : <VisibilityOff sx={{ fontSize: '14px' }} />}
                          </IconButton>
                        </InputAdornment>
                      }
                      onKeyPress={handleKeyPress}
                      style={{ fontSize: '12px' }}
                      placeholder={t('password')}
                    />
                    {confirmPasswordError && <small className='error-text'>{confirmPasswordError}</small>}
                  </div>
                  <Button
                    type='submit'
                    fullWidth
                    variant='contained'
                    style={{
                      borderRadius: '8px',
                      textTransform: 'none',
                      color: 'white',
                      backgroundColor: '#6926D9',
                      padding: '10px 0px',
                      fontWeight: '500',
                      fontSize: '16px'
                    }}
                    className='font-Manrope'
                    onClick={handleSubmit}
                  >
                    {t('save')}
                  </Button>
                </section>
              </div>
            </div>
            <Copyright />
          </div>
        )}
        {steps === 2 && (
          <div className='right-container'>
            <div className='d-flex flex-d-column rounded-10 form-cont'>
              <div className='logo-container gp-mt120'>
                <img src='https://d3brnpc5nhvc9v.cloudfront.net/static-assets-helloparts/logos/helloparts-Logo-x.png' alt='helloparts' height='40px' className='cp logo-style' onClick={goToHomePage} />
              </div>
              <div className='d-flex flex-d-column input-cont'>
                <section className='input-wrapper h_96'>
                  <Typography className='font-Manrope' style={{ fontWeight: '400 !important', width: '300px', color: '#919191' }}>
                    Ihr neues Passwort ist gespeichert und ab sofort gültig. Sie können sich nun mit Ihrem neuen Passwort anmelden.
                  </Typography>
                  <Button
                    type='submit'
                    fullWidth
                    variant='contained'
                    style={{
                      borderRadius: '8px',
                      textTransform: 'none',
                      color: 'white',
                      backgroundColor: '#6926D9',
                      padding: '10px 0px',
                      fontWeight: '500',
                      fontSize: '16px'
                    }}
                    className='font-Manrope gp-mt32'
                    onClick={navigateToLogin}
                  >
                    {t('backToRegistration')}
                  </Button>
                </section>
                <Copyright />
              </div>
            </div>
          </div>
        )}
      </section>
    </div>
  )
}

export default ResetPassword
