import React from 'react'
import { CDN_URL } from '../../constants/siteConstant'
import { goToHomePage } from '../../helpers/Utils'
import { Button } from '@mui/material'

export default function ViewRequestActiveStep4 () {
  return (
    <section className='step-2-wrapper'>
      <div className='step-2-contents'>
        <div className='content-wrapper'>
          <div className='logo-container gp-mt40 tc gp-mb20'>
            <img src='https://d3brnpc5nhvc9v.cloudfront.net/static-assets-helloparts/logos/helloparts-Logo-x.png' alt='helloparts' height='40px' className='cp logo-style' onClick={goToHomePage} />
          </div>
          <div className='gp-mt120 tc'>
            <img src={`${CDN_URL}/landing-page/success-bg.svg`} alt='helloparts' />
          </div>
          <div className='black-text gp-mt24 gp-text32 tc'>Ihr Benutzerkonto wurde erfolgreich erstellt!</div>
          <div className='light-grey-color gp-mt12 tc gp-text24'>Wir verifizieren Ihre Informationen und schalten schnellstmöglich Ihren Account frei.</div>
        </div>
        <div className='gp-mt24 gp-mb20 tc'>
          <Button
            onClick={goToHomePage}
            style={{
              borderRadius: '8px',
              textTransform: 'none',
              color: 'white',
              backgroundColor: '#6926D9',
              padding: '10px 30px 10px 30px',
              fontWeight: '500',
              fontSize: '14px'
            }}
          >
            <span className='font-DMSans gp-text18 font-weight-500 leading-26 text-white'>Schließen</span>
          </Button>
        </div>
      </div>
    </section>
  )
}
