import React, { useEffect } from 'react'
import '../../assets/styleSheets/sidebar.scss'
import { Box, Card, CardContent, Container, CssBaseline, Grid, IconButton, Typography } from '@mui/material'
import Navigation from '../sidebar/Navigation'

function Dashboard (props) {
  const { dashboardActions, authActions, dashboardState, authState } = props

  useEffect(() => {
    dashboardActions.displayServicesOnDashboard()
  }, [])

  const handleServiceClick = (url) => {
    window.open(url)
  }

  return (
    <>
      <div style={{ display: 'flex' }}>
        <Navigation authActions={authActions} authState={authState} dashboardState={dashboardState} />
        <Container
          component='main'
          maxWidth='100%'
          sx={{
            background: '#ECECEC',
            transition: 'margin-left 0.3s ease'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'center',
              justifyContent: 'center',
              overflow: 'auto',
              minHeight: '80vh',
              padding: '20px',
              width: '80%',
              margin: '0 auto'
            }}
          >
            <CssBaseline />
            <Grid container spacing={2} sx={{ marginTop: '20px' }}>
              {dashboardState?.dashboardData?.services?.map((service, index) => (
                <Grid key={index} item xs={6} sm={4} md={3}>
                  <Card
                    variant='elevation'
                    onClick={() => handleServiceClick(service.url)}
                    sx={{
                      width: '180px',
                      height: '160px',
                      textAlign: 'center',
                      cursor: 'pointer',
                      margin: '20px 0',
                      transition: 'transform 0.3s, border 0.3s',
                      backgroundColor: '#f7fafc',
                      borderRadius: '12px',
                      '&:hover': {
                        transform: 'translateY(-5px)'
                      }
                    }}
                  >
                    <CardContent>
                      <IconButton>
                        <img
                          src='https://d3brnpc5nhvc9v.cloudfront.net/static-images/parts_finder_img.png'
                          className='w-70 h-70'
                          loading='lazy'
                        />
                      </IconButton>
                      <Typography>{service.service_name || service.name}</Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Container>
      </div>
    </>
  )
}

export default Dashboard
