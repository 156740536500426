import React from 'react'

export default function AccountDetails ({ accountStateData }) {
  return (
    <div className='container mt-5'>
      <div className='card'>
        <div className='card-header clr-grey'>
          <h3>Account Details</h3>
        </div>
        <div className='card-body'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='mb-3 d-flex align-items-center'>
                <div className='col-sm-3'>ID:</div>
                <div className='col-sm-6'>account id</div>
              </div>
              <div className='mb-3 d-flex align-items-center'>
                <div className='col-sm-3'>Name:</div>
                <div className='col-sm-6'>{accountStateData?.accountData?.name}</div>
              </div>
              <div className='mb-3 d-flex align-items-center'>
                <div className='col-sm-3'>Email:</div>
                <div className='mb-0'>{accountStateData?.accountData?.email}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
